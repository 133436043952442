import { h } from 'preact';

function Icon() {
  return (
    <svg id="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0, 0, 400,400">
      <g id="svgg">
        <path id="path0" d="M186.800 67.265 C 127.299 72.836,77.787 118.430,67.843 176.808 C 55.457 249.524,102.692 317.404,175.255 331.168 C 205.030 336.815,241.466 330.829,262.199 316.883 C 265.918 314.382,266.037 314.823,259.365 306.396 C 256.144 302.328,251.904 296.975,249.943 294.500 C 247.983 292.025,246.241 290.000,246.072 290.000 C 245.903 290.000,243.703 291.045,241.182 292.322 C 213.664 306.265,174.310 303.452,147.000 285.590 C 80.845 242.321,88.001 140.623,159.505 107.864 C 211.159 84.199,275.625 111.765,293.798 165.290 C 295.232 169.515,295.171 169.591,291.521 168.094 C 273.066 160.524,253.877 171.747,253.282 190.458 C 252.871 203.417,257.070 210.467,282.191 238.996 C 308.095 268.415,312.060 272.800,312.757 272.800 C 313.131 272.800,315.783 270.148,318.655 266.900 C 321.524 263.655,327.457 256.950,331.839 252.000 C 368.890 210.142,372.223 205.135,372.179 191.400 C 372.115 171.665,350.908 159.424,332.736 168.633 C 330.073 169.982,329.813 169.807,329.011 166.131 C 315.648 104.913,252.628 61.101,186.800 67.265 " stroke="none" fill="currentColor" fill-rule="evenodd" />
      </g>
    </svg>
  );
}

export default function Loading() {
  return (
    <div className="klw-loading">
      <Icon />
    </div>
  );
}
